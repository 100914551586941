import './App.css';
import Keyboard from './Keyboard.js'
import React, { useState, useEffect } from 'react';
import trueWords from './trueWords.js';
import AdBox from './AdBox.js';


function Game(props) {

  const [currentGuess, set_currentGuess] = useState("");
  const [guessedLetters, set_guessedLetters] = useState("");
  const [completedRowsArr, set_completedRowsArr] = useState([]);
  const [pageState, set_pageState] = useState("GUESSING"); // GUESSING or COMPLETE

  const currentWord = props.words[props.currentHoleIndex];
  const currentHoleIndex = props.currentHoleIndex;


  useEffect(()=>{

    console.log("WORD CHANGED")
    set_pageState("GUESSING")
    set_completedRowsArr([])
    set_guessedLetters("")

  },[currentHoleIndex])


  useEffect(()=>{

    if (pageState === "COMPLETE") {
      props.completeHole(completedRowsArr)
    }

  },[pageState])




  const addLetter = (letter) => {
    if (currentGuess.length < 5) {
      set_currentGuess(`${currentGuess}${letter}`)
    }
  }

  const deleteLetter = () => {
    if (currentGuess.length > 0) {
      set_currentGuess(currentGuess.substring(0, currentGuess.length - 1))
    }
  }



  const addGuess = () => {

    if (currentGuess.length === 5) {

      if (trueWords.includes(currentGuess)){
      
            let tempcompletedRowsArr = completedRowsArr;
      
            let wordArrTemp = [...currentWord]
            let guessArrTemp = [...currentGuess]
            let resultArr = [..."NNNNN"];
      
            // calculate Result Array
            // 1) Look for exact matches
            [...currentGuess].forEach((thisLetter,i)=>{
              if ( thisLetter === wordArrTemp[i] ) {
                resultArr[i] = "Y";
                wordArrTemp[i] = "-";
                guessArrTemp[i] = "*";
              }
            });
      
            console.log("WORD TEMP ARRAY BEFORE SECOND ROUND",wordArrTemp); // ['C', '-', 'R', 'R', 'Y']
            console.log("RESULT ARRAY BEFORE SECOND ROUND",resultArr);      // ['N', 'Y', 'N', 'N', 'N']
            console.log("GUESS ARRAY BEFORE SECOND ROUND",guessArrTemp);    // ['R', '*', 'D', 'A', 'R']
            
            [...guessArrTemp].forEach((thisLetter,i)=>{
              let letterIndexIfFound = wordArrTemp.indexOf(thisLetter);
              if ( letterIndexIfFound !== -1 ) {
                resultArr[i] = "+"
                wordArrTemp[letterIndexIfFound] = "-"
              }
            });
      
            console.log("RESULT ARRAY",resultArr)        // ['+', 'Y', 'N', 'N', 'N']
            console.log("RESULT WORD ARRAY",wordArrTemp) // ['-', '-', 'R', 'R', 'Y']
      
            tempcompletedRowsArr.push({
              "index": completedRowsArr.length,
              "word": currentGuess,
              "resultArr": resultArr,
              });
            set_completedRowsArr(tempcompletedRowsArr)
            set_guessedLetters(guessedLetters + currentGuess)
            if (currentGuess === currentWord) { set_pageState("COMPLETE") }
            set_currentGuess("")
            console.log("CHANGING TO", tempcompletedRowsArr);
            console.log("CURRENT GUESSED LETTERS", guessedLetters + currentGuess);
          } else {
            set_currentGuess("");
          }

        }
      }

  const resultText = {
    0: "No words",
    1: "ACE! Holy cow, that's awesome!",
    2: "Nice Eagle!",
    3: "A Solid Birdie!",
    4: "Par is always nice!",
    5: "Doh! A Bogey!",
    6: "Double Bogey! Wah Wah Wah!",
    7: "Triple Bogey. That hurts!",
    8: "The dreaded snowman. Ouch!",
    9: "That's a +5",
    10: "That's a +6",
    11: "That's a +7",
    12: "That's a +8",
    13: "This may not be your game! +9",
    14: "This may not be your game! +10",
    15: "This may not be your game! +11",
    16: "This may not be your game! +12",
    17: "This may not be your game! +13",
  }


  // GET THIS FROM LOCAL STORAGE
  // const completedRowsArr = ;


  const calculateLetterResult = (obj, ind) => {
    if (obj.resultArr[ind] === "Y") {
      return "ResultCorrectPlace"
    } else if (obj.resultArr[ind] === "+") {
      return "ResultCorrectWrongPlace"
    } else {
      return "ResultWrong"
    }
  }


  const HoleCompleteDisplay = () => {

    return (

      <div className="ResultBox">
        <h4 className="ResultHead">{resultText[completedRowsArr.length]}</h4>
        <div className="TriviaBox">
          <p className="AnswerDescriptionTitle"><b>Go Jerry Trivia</b></p>
          <p className="AnswerDescription">{props.descriptions[props.currentHoleIndex]}</p>
        </div>
          {currentHoleIndex < 8 ? <button className="NextHoleButton" onClick={()=>{
                    console.log("clicked");
                    props.nextHole()
                  }}>Next Hole</button> : <button onClick={props.handleClick} className="ViewScorecardEndButton">View Scorecard</button>}
      </div>

    )

  }

  const CompletedRow = (obj) => {
    return (
          <div className="GuessRow" key={obj.index}>
            <div className="GuessIndex">
              <div className="guessIndexText">{obj.index+1}</div>
            </div>
            <div className={`GuessBox ${calculateLetterResult(obj, 0)}`}>
              <div className="GuessText">{obj.word.charAt(0)}</div>
            </div>
            <div className={`GuessBox ${calculateLetterResult(obj, 1)}`}>
              <div className="GuessText">{obj.word.charAt(1)}</div>
            </div>
            <div className={`GuessBox ${calculateLetterResult(obj, 2)}`}>
              <div className="GuessText">{obj.word.charAt(2)}</div>
            </div>
            <div className={`GuessBox ${calculateLetterResult(obj, 3)}`}>
              <div className="GuessText">{obj.word.charAt(3)}</div>
            </div>
            <div className={`GuessBox ${calculateLetterResult(obj, 4)}`}>
              <div className="GuessText">{obj.word.charAt(4)}</div>
            </div>
          </div>
    )
  }

  const BlankRow = (i) => {
    return (
          <div className="GuessRow" key={i.index}>
            <div className="GuessIndex">
              <div className="guessIndexText">{i.index+1}</div>
            </div>
            <div className="GuessBox">
              <div className="GuessText">{'\xA0'}</div>
            </div>
            <div className="GuessBox">
              <div className="GuessText">{'\xA0'}</div>
            </div>
            <div className="GuessBox">
              <div className="GuessText">{'\xA0'}</div>
            </div>
            <div className="GuessBox">
              <div className="GuessText">{'\xA0'}</div>
            </div>
            <div className="GuessBox">
              <div className="GuessText">{'\xA0'}</div>
            </div>
          </div>
    )
  }

  const ActiveLine = (obj) => {
    return (
          <div className="GuessRow">
            <div className="GuessIndex">
              <div className="guessIndexText">{completedRowsArr.length+1}</div>
            </div>
            <div className="GuessBox">
              <div className="GuessText">{obj.word.charAt(0) ? obj.word.charAt(0) : '\xA0' }</div>
            </div>
            <div className="GuessBox">
              <div className="GuessText">{obj.word.charAt(1) ? obj.word.charAt(1) : '\xA0' }</div>
            </div>
            <div className="GuessBox">
              <div className="GuessText">{obj.word.charAt(2) ? obj.word.charAt(2) : '\xA0' }</div>
            </div>
            <div className="GuessBox">
              <div className="GuessText">{obj.word.charAt(3) ? obj.word.charAt(3) : '\xA0' }</div>
            </div>
            <div className="GuessBox">
              <div className="GuessText">{obj.word.charAt(4) ? obj.word.charAt(4) : '\xA0' }</div>
            </div>
          </div>
    )
  }


  const CompletedRows = () => {
    return completedRowsArr.map((aRow) => {
      return (
            <CompletedRow {...aRow} />
      )
    }) 
  }


  function blankRows(howmany) {
    console.log("HOW MANY BLANK", howmany)
    let brArr = []

    for (let x=0; x < howmany; x++) {
        brArr.push(<BlankRow index={x+completedRowsArr.length+1} />)
    }
    return brArr;
  }



  return (
    <div className="Game">
      <div className="CardTop">
        <div className="LeftTopBox">
          <h1>&nbsp;</h1>
        </div>
        <div className="CenterTopBoxGame">
          <h1>Hole #{props.currentHoleIndex+1}<br />PAR 4</h1>
        </div>
        <div className="RightTopBox">
          <button onClick={props.handleClick} className="FlipButton">View Scorecard</button>
        </div>
      </div>
      
      <div className="GuessAreaContainer">
        <div className="TableHeadRow" key="999">
          <div className="GuessIndexHead">
            <div className="guessIndexTextHead">STROKE</div>
          </div>
        </div>
        <CompletedRows />
        {pageState === "GUESSING" ? <ActiveLine word={currentGuess} /> : null }
        {pageState === "GUESSING" ? blankRows(3-completedRowsArr.length) : null }
      </div>

      {pageState === "GUESSING" ? <Keyboard currentGuessLength={currentGuess.length} currentWord={currentWord} addGuess={addGuess} addLetter={addLetter} deleteLetter={deleteLetter} guessedLetters={guessedLetters} /> : <HoleCompleteDisplay /> }
      
      <AdBox />

    </div>
  )
}

export default Game;