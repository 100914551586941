import './App.css';
import React from 'react';
import ReactCardFlip from 'react-card-flip';
import Game from './Game.js'
import Scorecard from './Scorecard.js'
import ReactModal from 'react-modal';
import StartModalContent from './StartModalContent.js'
import EndModalContent from './EndModalContent.js'

class App extends React.Component {
  constructor() {
    super();
      this.state = {
      isFlipped: false,
      scorecard: [], // Array of scores, saved as indexs
      words: ["HAZEL", "SPORT", "ANGEL", "PARIS", "CHAIN", "DRUGS", "WHITE", "MEDIA", "JERRY"],
      descriptions: [
        "Jerry's eyes are hazel.",
        "Isn't this one kind of obvious?",
        "Angel is Jerry's mother's name.",
        "Paris is Jerry's favorite holiday destination.",
        "Chains. Jerry wears em. Around his neck.",
        "Jerry has shared his stories of addiction in the hopes that they may help others.",
        "Jerry's white, although we don't see color.",
        "Jerry has a large following on social media.",
        "Like come on, you didn't think this would be one of the answers?"
      ],

      currentHoleIndex: 0,
      // TESTING scorecard: [2,3,5,3,5,3,2],
      // TESTING currentHoleIndex: 7,
      showModalStart: true,
      showModalEnd: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.completeHole = this.completeHole.bind(this);
    this.nextHole = this.nextHole.bind(this);
    this.triggerShare = this.triggerShare.bind(this);

    this.handleOpenModalStart = this.handleOpenModalStart.bind(this);
    this.handleOpenModalEnd = this.handleOpenModalEnd.bind(this);
    this.handleCloseModalStart = this.handleCloseModalStart.bind(this);
    this.handleCloseModalEnd = this.handleCloseModalEnd.bind(this);

  }

  handleOpenModalStart() {
    this.setState({ showModalStart: true });
  }
  
  handleOpenModalEnd() {
    this.setState({ showModalEnd: true });
  }
  
  handleCloseModalStart() {
    this.setState({ showModalStart: false });
  }
  
  handleCloseModalEnd() {
    this.setState({ showModalEnd: false });
  }
  
  triggerShare = async (url, score) => {
    const shareData = {
        title: 'Pardle - The Golf Word Game',
        text: 'I just scored ' + score + ' on Pardle! @PardleGolf',
        url: url,
      };

      let shareResultPara = "";

    try {
      await navigator.share(shareData)
      shareResultPara = 'Pardle shared successfully'
    } catch(err) {
      shareResultPara = 'Error: ' + err
    }

  }



  completeHole(obj) {
    // obj received is completedHolesArr

    // Add completed hole to local storage dataset
    // Add to scorecard in state
    this.setState(state => ({
      scorecard: [...state.scorecard, obj.length-1],
    }))
  }

  nextHole() {
    this.setState(state => ({
      currentHoleIndex: state.scorecard.length,
    }))
  }

  handleClick(e) {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  render() {
    return (
      <div class="AppContainer">

        <ReactModal 
          isOpen={this.state.showModalStart}
          contentLabel="Welcome to Pardle!"
          onRequestClose={this.handleCloseModalStart}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.55)',
            },
            content: {
              position: 'absolute',
              top: '20px',
              left: '20px',
              right: '20px',
              bottom: '20px',
              border: '8px solid #666',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '16px',
              outline: 'none',
              padding: '20px',
              boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.35)',
            }
          }}
        >
          <StartModalContent handleCloseModalStart={this.handleCloseModalStart}/>
        </ReactModal>

        <ReactModal 
          isOpen={this.state.showModalEnd}
          contentLabel="Thank you for playing Pardle!"
          onRequestClose={this.handleCloseModalEnd}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.55)',
            },
            content: {
              position: 'absolute',
              top: '20px',
              left: '20px',
              right: '20px',
              bottom: '20px',
              border: '1px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '16px',
              outline: 'none',
              padding: '20px',
              boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.35)',
            }
          }}
        >
          <EndModalContent handleCloseModalEnd={this.handleCloseModalEnd}/>
        </ReactModal>



        <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal" className="AppContainer">

          <span className="Game">
            <Game 
              handleClick={this.handleClick} 
              completeHole={this.completeHole} 
              nextHole={this.nextHole} 
              currentHoleIndex={this.state.currentHoleIndex} 
              words={this.state.words}
              descriptions={this.state.descriptions}
              triggerShare={this.triggerShare} />
          </span>
          <span className="Scorecard">
            <Scorecard 
              handleClick={this.handleClick} 
              scorecardData={this.state.scorecard}
              triggerShare={this.triggerShare}
              handleOpenModalEnd={this.handleOpenModalEnd} />
          </span>

        </ReactCardFlip>
      </div>
    )
  }
}

export default App;
