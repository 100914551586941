import './App.css';


function StartModalContent(props) {

  return (
    <div className="ModalContent" style={{fontSize: 12}}>

      <div className="StartTopContainer">
        <div className="StartTopLeft">
          <img src="/enterlogo.png" height="50px" alt="Pardle"/>
        </div>
        <div className="StartTopRight">
          <button className="CloseButton" onClick={props.handleCloseModalStart}>Play Now</button>
        </div>
      </div>

      <h4>GO JERRY EDITION</h4>
      <p>We are as glued to our screens as we're sure you are watching Jerry After Dark's Hole in One Challenge on YouTube. He's come close, but we're betting we'll get to enjoy this for quite a while more.</p>
      <p>We also have it on good authority that the odds of hitting a hole in one are about 1 in 12,000.</p>
      <p>In any case, <a href="https://www.youtube.com/watch?v=vd4IuhAcI50" target="_blank">Check it out here</a></p>
      <p>On to the game... Find the <b>"Jerry-related" word</b> for each of the nine par-4 holes.
      Keep playing until you guess the word – there’s no gimmes in Pardle!</p>
      <p>Each guess you make must be a valid 5 letter word <b>but doesn't have to be a "Jerry-related" word</b>. Hit the <b>Shoot</b> button to test your guess.</p>

      <hr />

      <p>After each guess, the tiles will show you how close your guess was to the word.</p>

      <img src="/sampleguess.jpg" width="200px" alt="" />
      <p>In the example above, the letter <b>E</b> is in the word and in the correct spot. The letter <b>D</b> is in the word but in the wrong spot.</p>

      <hr />

      <h5>Good Luck!</h5>

      <p className="legalcopy">Inquiries: <a href="mailto:info@pardle.golf">info@pardle.golf</a>. This is a golf-themed version of the game Wordle. Play the original <a href="https://www.powerlanguage.co.uk/wordle/" target="_blank" rel="noreferrer">here</a>. To view the Pardle.golf Privacy Policy click <a href="/privacy-policy.html" target="_blank" rel="noreferrer">here</a>. ©2022 13752666 Canada Inc.</p>

    </div>
  )
}

export default StartModalContent;