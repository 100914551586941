import './App.css';
import React, { useEffect } from 'react';
import AdBox from './AdBox.js';

function Scorecard(props) {

  useEffect(()=>{

    console.log("LENGTH OF SCORES CHANGED")

  },[props.scorecardData.length])



  let week = 12;
  let shareUrl = "https://pardle.golf/score/" + week;

  console.log("CURRENT SCORE DATA", props.scorecardData)

  const scorecardRows = () =>{

    const holeScoresTemp = Array.from({...props.scorecardData, length:9});

    const holesDisplayTempArr = holeScoresTemp.map((h,i)=>{

      shareUrl = h !== undefined ? `${shareUrl}_${h+1}` : shareUrl;
      
      return(
        <div className="HoleRow">
          <div className="HoleNumberContainer">
            <span className="HoleNumberText">{i+1}</span>
          </div>
          <div className="HoleScoreContainer">
            <span className="HoleScoreText">{h !== undefined ? props.scorecardData[i]+1 : '\xA0'}</span>
          </div>
          <div className="HoleScoreContainer">
            <span className={`HoleScoreText ${cumulativeToPar(i).color}`}>{h !== undefined ? cumulativeToPar(i).text : '\xA0'}</span>
          </div>
        </div>
      )})

    return holesDisplayTempArr
  }



  const calculateScoreToPar = () => {

    const currentToParTotal = props.scorecardData.reduce((a, b) => a + b-3, 0);

    console.log("CALCULATED TOTAL",currentToParTotal )

    let returnText = "";

    if (currentToParTotal === 0) {
      returnText = "E";
    } else if (currentToParTotal > 0) {
      returnText = `+${currentToParTotal}`;
    } else {
      returnText = `${currentToParTotal}`;
    }

    return returnText

  }

  const cumulativeToPar = (i) => {

    const cumulativeArr = props.scorecardData.slice(0, i+1)
    let parColor = "ParBlack"

    const currentToParTotal = cumulativeArr.reduce((a, b) => a + b-3, 0);

    console.log("CALCULATED TOTAL",currentToParTotal )

    let returnText = "";

    if (currentToParTotal === 0) {
      returnText = "E";
      parColor = "ParGreen"
    } else if (currentToParTotal > 0) {
      returnText = `+${currentToParTotal}`;
      parColor = "ParGreen"
    } else {
      returnText = `${currentToParTotal}`;
      parColor = "ParRed"
    }

    return ({text: returnText, color:parColor})

  }

  return (
    <div className="Scorecard">
      <div className="CardTop">
      <div className="LeftTopBox">
        <h1>&nbsp;</h1>
      </div>
      <div className="CenterTopBoxScorecard">
        <h1>Scorecard</h1>
      </div>
        <div className="RightTopBox">
          <button onClick={props.handleClick} className="FlipButton">Continue Round</button>
        </div>
      </div>
      <div className="ScorecardBodyArea">

        <div className="ScorecardLeftCol">
          <div className="TableHeadRow" key="999">
            <div className="GuessIndexHead">
              <div className="guessIndexTextHead">HOLE</div>
            </div>
            <div className="GuessIndexHead">
              <div className="guessIndexTextHead">SCORE</div>
            </div>
            <div className="GuessIndexHead">
              <div className="guessIndexTextHead">TO PAR</div>
            </div>
          </div>
          {scorecardRows()}

        </div>

        <div className="ScorecardRightCol">

          <div className="ToParTotalContainer">
            <div className="ToParTotalHead">
              TOTAL TO PAR<br />THRU {props.scorecardData.length} HOLES
            </div>
            <div className="ToParTotalScore">
              {calculateScoreToPar()}
            </div>
          </div>

          <div className="ShareButtonContainer">
            <button className="ShareNowButton" onClick={()=>{
              console.log("clicked");
              props.triggerShare(shareUrl,calculateScoreToPar())
            }}>SHARE NOW</button>
          </div>
          
        </div>
      </div>
      <AdBox />
    </div>
  )
}

export default Scorecard;