import './App.css';


function EndModalContent(props) {

  return (
    <div className="ModalContent">
          <p>End Modal text!</p>
          <button onClick={props.handleCloseModalEnd}>End Modal Start</button>
    </div>
  )
}

export default EndModalContent;