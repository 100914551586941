import React, { Component  } from 'react'
import './App.css';

class AdBox extends Component {

    componentDidMount() {
     //(window.adsbygoogle = window.adsbygoogle || []).push({})
    }

   render () {
    return(
        <div className="AdBox">
        </div>)
    }
}

export default AdBox


/*

Uncomment Line 7 and add this inside the div...

			<ins class="adsbygoogle"
		     style={{display:'block',width:"100%",height:"90px"}}
		     data-ad-client="ca-pub-1816988511219097"
		     data-ad-slot="5844032669"
		     data-ad-format="auto"
		     data-full-width-responsive="true"></ins>

*/