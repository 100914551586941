import './App.css';

function Keyboard(props) {

  const keyConstructor = (key) => {

    let keyState = props.currentWord.includes(key) && props.guessedLetters.includes(key) ? 'GuessedRight' : props.guessedLetters.includes(key) ? 'Guessed' : null;
    
    return (<div className={`LetterKey ${keyState}`} onClick={()=> props.addLetter(key)}>{key}</div>)
  }

    return (
      <div className="Keyboard">
          <div className="KeysRow">
            {keyConstructor("Q")}
            {keyConstructor("W")}
            {keyConstructor("E")}
            {keyConstructor("R")}
            {keyConstructor("T")}
            {keyConstructor("Y")}
            {keyConstructor("U")}
            {keyConstructor("I")}
            {keyConstructor("O")}
            {keyConstructor("P")}
        </div>
          <div className="KeysRow">
            {keyConstructor("A")}
            {keyConstructor("S")}
            {keyConstructor("D")}
            {keyConstructor("F")}
            {keyConstructor("G")}
            {keyConstructor("H")}
            {keyConstructor("J")}
            {keyConstructor("K")}
            {keyConstructor("L")}
        </div>
          <div className="KeysRow">
            {props.currentGuessLength === 5 ? <div className={`GuessKey`} onClick={()=> props.addGuess()}>Shoot</div> : <div className={`GuessKey Greyed`}>Shoot</div>}
            {keyConstructor("Z")}
            {keyConstructor("X")}
            {keyConstructor("C")}
            {keyConstructor("V")}
            {keyConstructor("B")}
            {keyConstructor("N")}
            {keyConstructor("M")}
            <div className="DeleteKey" onClick={()=> props.deleteLetter()}>Del</div>
        </div>
      </div>
    )
  }

export default Keyboard;